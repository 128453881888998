import React from "react";

function AboutUsIntroductItem(props) {
  const [customClassName, setCustomeClassName] = React.useState("aui-hide");
  const divRef = React.useRef();

  React.useEffect(() => {
    const windowScrollHandle = (e) => {
      const wHeight = window.innerHeight;
      const top = divRef.current.getBoundingClientRect().top - wHeight / 1.5;
      if (top < 0) {
        setCustomeClassName("aui-show");
      } else {
        setCustomeClassName("aui-hide");
      }
    };
    window.addEventListener("scroll", windowScrollHandle);
    return () => {
      window.removeEventListener("scroll", windowScrollHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="aui-item" id={props.data.id} ref={divRef}>
      <div className="aui-img-wrapper">
        <img src={props.data.src} className="img-fluid" alt="" />
      </div>
      <div
        className={
          `aui-content ${props.data.className} ${customClassName}` +
          (props.data.ttl ? " has-title" : "")
        }
      >
        <div dangerouslySetInnerHTML={{ __html: props.data.ttl }}></div>
        <div
          className="aui-text"
          dangerouslySetInnerHTML={{ __html: props.data.txt }}
        ></div>
      </div>
    </div>
  );
}

function AboutUsIntroduct({ data }) {
  return (
    <div className="about-us-introduct">
      {data.map((item, index) => (
        <AboutUsIntroductItem data={item} key={index} />
      ))}
    </div>
  );
}

export default AboutUsIntroduct;
