import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import config from "../config";
import Loading from "../partials/Loading";

function PrevNext({ serverData, folder }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const data = serverData;

  return (
    <>
      {data.prev != null && (
        <div className="navigation-prev" data-name="Prev">
          <Link to={`/${language}/${folder}/${data.prev.id}`} rel="prev">
            <img
              src={`${config.baseURL}/storage/${data.prev.thumbnail}`}
              style={{ width: 100 }}
            />
          </Link>
        </div>
      )}
      {data.next != null && (
        <div className="navigation-next" data-name="Next">
          <Link to={`/${language}/${folder}/${data.next.id}`} rel="next">
            <img
              src={`${config.baseURL}/storage/${data.next.thumbnail}`}
              style={{ width: 100 }}
            />
          </Link>
        </div>
      )}
    </>
  );
}

export default PrevNext;
