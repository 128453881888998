import React from "react";
import Banner from "../components/Banner";
import Breadcrumb from "../partials/Breadcrumb";
import ProductsIntroduct from "../components/ProductsIntroduct";
import config from "../config";
import ScrollLoadData2 from "../partials/ScrollLoadData2";
import { resource } from "../fetchDataApp";
import { useTranslation } from "react-i18next";

function WinePackaging() {
  const serverData = resource.winePage.read();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const bannerDataItem = {
    src: `${config.baseURL}/storage/${serverData.page.banner}`,
  };

  const breadcrumbData = [
    { title: t("home"), link: "/" },
    { title: t("wine_packaging") },
  ];

  window.scrollTo(0, 0);

  return (
    <div className="producst">
      <Banner data={bannerDataItem} />
      <Breadcrumb data={breadcrumbData} />
      <ScrollLoadData2 serverData={serverData} />
    </div>
  );
}

export default WinePackaging;
