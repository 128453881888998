const languagePublisher = {
    data: [],
    publish: function(state){
        this.data.forEach(item => {
            item.cb(state);
        })
    },
    subscribe: function(id, cb) {
        this.data.push({id, cb});
    },
    unsubscribe: function(id) {
        this.data = this.data.filter(item => item.id !== id);
    }
};

export default languagePublisher;