import React from "react";
import Banner from "../components/Banner";
import AboutUsIntroduct from "../components/AboutUsIntroduct";
import config from "../config";
import { useTranslation } from "react-i18next";
import { resource } from "../fetchDataApp";
import Breadcrumb from "../partials/Breadcrumb";

function AboutUs() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const serverData = resource.aboutUsPage.read();

  const aboutUsIntroductData = [
    {
      src: `${config.baseURL}/storage/${serverData.a_image}`,
      ttl: serverData["a_title_" + language],
      className: "aui-right",
      txt: serverData["a_desc_" + language],
    },
    {
      src: `${config.baseURL}/storage/${serverData.b_image}`,
      ttl: serverData["b_title_" + language],
      className: "aui-left",
      txt: serverData["b_desc_" + language],
    },
    {
      src: `${config.baseURL}/storage/${serverData.c_image}`,
      ttl: serverData["c_title_" + language],
      className: "aui-left",
      txt: serverData["c_desc_" + language],
    },
    {
      src: `${config.baseURL}/storage/${serverData.d_image}`,
      ttl: serverData["d_title_" + language],
      className: "aui-right",
      txt: serverData["d_desc_" + language],
    },
  ];

  const bannerDataItem = {
    src: `${config.baseURL}/storage/${serverData.banner}`,
  };

  const breadcrumbData = [
    { title: t("home"), link: "/" },
    { title: t("about_us") },
  ];

  window.scroll(0, 0);

  return (
    <div className="about-us mb-5">
      <Banner data={bannerDataItem} />
      <Breadcrumb data={breadcrumbData} />
      <AboutUsIntroduct data={aboutUsIntroductData} />
    </div>
  );
}

export default AboutUs;
