import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

function ImageBoxCard2({ data }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  //console.log("loading image box card");

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <div className="image-box-card2">
        <div className="ibc-head mb-2">
          <img
            src={config.baseURL + "/storage/" + data.thumbnail}
            className="img-fluid"
          />
          <Link to={`/${language}/products/${data.id}`}>{t("view")}</Link>
        </div>
        <div className="ibc-content">
          <div className="mb-1 ibc-content-cat">
            {data.categoryEntity.map((item, index) => (
              <span key={index}>{item["title_" + language]}</span>
            ))}
          </div>
          <Link
            to={`/${language}/products/${data.id}`}
            className="text-dark text-decoration-none"
          >
            <h5>{data["title_" + language]}</h5>
          </Link>
          <p>{data["desc_" + language]}</p>
        </div>
      </div>
    </div>
  );
}

export default ImageBoxCard2;
