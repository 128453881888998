import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FastField, Field } from "formik";
import config from "../config";

/* import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'; */

function ContactUsForm() {
  const { t } = useTranslation();
  const [serverMessage, setServerMessage] = React.useState("");
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmitHandle = async (values, { setSubmitting, resetForm }) => {
    /*         const token = await executeRecaptcha("home");
        console.log(JSON.stringify(token)); */

    fetch(`${config.baseAPI}/enquiry`, {
      crossDomain: true,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message == "success") {
          setServerMessage(t("send_enquiry_success"));
        } else {
          setServerMessage(t("send_enquiry_fail"));
        }
        resetForm({});
        setSubmitting(false);
      });
  };

  return (
    <div className="contact-us-form">
      {serverMessage != "" && (
        <div className="alert alert-success">{serverMessage}</div>
      )}
      <Formik
        initialValues={{
          company: "",
          name: "",
          email: "",
          tel: "",
          question_type: "",
          region: "",
          inquiry: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.company) {
            errors.company = t("company_is_required");
          }
          if (!values.name) {
            errors.name = t("name_is_required");
          }
          if (!values.email) {
            errors.email = t("email_is_required");
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t("email_is_invalid");
          }
          /*           if (!values.tel) {
            errors.tel = t("tel_is_required");
          } */
          /*           if (!values.question_type) {
            errors.question_type = t("question_type_is_required");
          }
 */
          /*           if (!values.region) {
            errors.region = t("region_is_required");
          }
          if (!values.inquiry) {
            errors.inquiry = t("inquiry_is_required");
          } */
          return errors;
        }}
        onSubmit={onSubmitHandle}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
        }) => (
          <Form>
            <div className="form-group">
              <Field
                name="company"
                placeholder={t("company") + " *"}
                className={
                  "form-control" +
                  (errors.company && touched.company ? " border-danger" : "")
                }
                value={values.company}
              />
            </div>
            <div className="form-group">
              <Field
                name="name"
                placeholder={t("name") + " *"}
                className={
                  "form-control" +
                  (errors.name && touched.name ? " border-danger" : "")
                }
                value={values.name}
              />
            </div>
            <div className="form-group">
              <Field
                name="email"
                placeholder={t("email") + " *"}
                className={
                  "form-control" +
                  (errors.email && touched.email ? " border-danger" : "")
                }
                value={values.email}
              />
            </div>
            <div className="form-group">
              <Field
                name="tel"
                placeholder={t("phone")}
                className="form-control"
                value={values.tel}
              />
            </div>
{/*             <div className="form-group">
              <select
                name="question_type"
                value={values.question_type}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
                style={{
                  border: "1px solid #eeeeee",
                  borderRadius: 0,
                  height: 46,
                  color: "#2a2f35",
                }}
              >
                <option
                  value=""
                  label={"--" + t("select_question_type") + "--"}
                />
                <option
                  value={t("packaging_for_cosmetic_skincare")}
                  label={t("packaging_for_cosmetic_skincare")}
                />
                <option
                  value={t("packaging_for_chocolate")}
                  label={t("packaging_for_chocolate")}
                />
                <option
                  value={t("packaging_for_wine")}
                  label={t("packaging_for_wine")}
                />
                <option
                  value={t("stationery_item")}
                  label={t("stationery_item")}
                />
                <option value={t("others")} label={t("others")} />
              </select>
            </div> */}
            <div className="form-group">
              <Field
                name="region"
                placeholder={t("region")}
                className="form-control"
                value={values.region}
              />
            </div>
            <div className="form-group">
              <Field
                as="textarea"
                name="inquiry"
                placeholder={t("message")}
                className="form-control"
                value={values.inquiry}
              />
            </div>

            <button
              className="contact-us-form-btn btn d-block w-100"
              disabled={isSubmitting}
            >
              {t("submit")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactUsForm;
