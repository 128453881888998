/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SocialMedia() {
  const { i18n } = useTranslation();
  const language = i18n.language;
  return (
    <div className="social-media">
      <Link
        to={`/${language}/contact-us?scroll=true`}
        className="text-decoration-none"
      >
        <img src="/images/social-media/ty_contactus.svg" alt="" />
      </Link>
    </div>
  );
}

export default SocialMedia;
