/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import $ from "jquery";

function BackToTop() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    $(function () {
      $(window).on("scroll", function (e) {
        const wHeight = $(this).height();
        const bodyHeight = $("body").height();
        const scrollTop = $(this).scrollTop();
        const bottom = bodyHeight - wHeight - 100;
        if (scrollTop >= bottom) {
          setShow(true);
        } else {
          setShow(false);
        }
      });
    });
    $("#back-to-top").on("click", function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        400
      );
    });
  }, []);

  return (
    <div className={"back-to-top" + (show ? " show" : "")} id="back-to-top">
      <img src="/images/back-to-top.svg" alt="" />
    </div>
  );
}

export default BackToTop;
