import React from "react";
import Banner from "../components/Banner";

function PageNotFound() {
  const bannerDataItem = {
    src: "/images/page-not-found.jpg",
  };
  return (
    <>
      <Banner data={bannerDataItem} />
      <div
        className="container my-5 d-flex align-items-center justify-content-center"
        style={{ height: 145 }}
      >
        <h2 className="page-not-found text-center">Page Not Found</h2>
      </div>
    </>
  );
}

export default PageNotFound;
