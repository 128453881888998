import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DesktopMenu() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className="d-none d-lg-block">
      <NavLink
        className="mx-3 text-white text-decoration-none text-uppercase"
        to={`/${language}`}
      >
        {t("home")}
      </NavLink>
      <NavLink
        className="mx-3 text-white text-decoration-none text-uppercase"
        to={`/${language}/about-us`}
      >
        {t("about_us")}
      </NavLink>
      <NavLink
        className="mx-3 text-white text-decoration-none text-uppercase"
        to={`/${language}/products`}
      >
        {t("product_gallery")}
      </NavLink>
      <NavLink
        className="mx-3 text-white text-decoration-none text-uppercase"
        to={`/${language}/wine-packaging`}
      >
        {t("wine_packaging")}
      </NavLink>
      <NavLink
        className="mx-3 text-white text-decoration-none text-uppercase"
        to={`/${language}/contact-us`}
      >
        {t("contact_us")}
      </NavLink>
    </div>
  );
}

export default DesktopMenu;
