import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import config from "../config";

function LeftImageRightContent({ serverData }) {
  const facebookMetaTag = $(
    '<meta property="og:url" content="https://tongyuen.hkictech.com/en/products/1" /> <meta property="og:type" content="website" /><meta property="og:title" content="Tongyuen" /> <meta property="og:description" content="change the descripte" /> <meta property="og:image" content="https://tongyuen.hkictech.com/images/products/fb-lamp-2.jpg" />'
  );
  $("head").append(facebookMetaTag);

  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const data = serverData;

  return (
    <div className="left-image-right-content mb-5">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-md-7">
            {data.media.map((item, index) => {
              var ele = "";
              var ext = item.content.split(".").pop();
              if (
                ext == "jpg" ||
                ext == "jpeg" ||
                ext == "png" ||
                ext == "gif"
              ) {
                ele = `<img src="${config.baseURL}/storage/${item.content}" class="w-100 mb-4" />`;
              } else {
                ele = `<video width="100%" height="100%" controls>
                                        <source src="${config.baseURL}/storage/${item.content}" type="video/mp4" />
                                    </video>`;
              }
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: ele }}
                ></div>
              );
            })}
          </div>
          <div className="col-12 col-md-5">
            {language === "en" && (
              <div
                dangerouslySetInnerHTML={{ __html: data.product.content_en }}
              ></div>
            )}
            {language === "tc" && (
              <div
                dangerouslySetInnerHTML={{ __html: data.product.content_tc }}
              ></div>
            )}
            {language === "sc" && (
              <div
                dangerouslySetInnerHTML={{ __html: data.product.content_sc }}
              ></div>
            )}
            {/*             <FacebookShareButton
              url="https://tongyuen.hkictech.com/en/products/1"
              quote={"CampersTribe - World is yours to explore"}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftImageRightContent;
