import React from "react";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import config from "../config";

function SearchResult({ server, ...res }) {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const { language } = useParams();
  const bannerDataItem = {
    src: "/images/home/lovely-couple.jpg",
  };
  const serverData = server.read();

  const goLink = (language, path) => {
    i18n.changeLanguage(language);
    history.push(path);
  };

  return (
    <div className="">
      <Banner data={bannerDataItem} />
      <div className="container">
        <h1 className="my-4">{t("search_result")}</h1>
        {serverData.length <= 0 && <div>{t("no_result")}</div>}
        {serverData.map((item, index) => (
          <div
            key={index}
            className="cursor-pointer my-3 d-flex align-items-center"
          >
            <div>
              <img
                src={`${config.baseURL}/storage/${item.thumbnail}`}
                style={{ width: 60 }}
                className="mr-3"
              />
            </div>
            <div>
              <span
                className="y-2 bg-white text-dark text-decoration-none pr-2"
                onClick={() => goLink("en", `/en/${item.kind}/${item.id}`)}
              >
                {item.title_en}
              </span>
              /
              <span
                className="y-2 bg-white text-dark text-decoration-none px-2"
                onClick={() => goLink("tc", `/tc/${item.kind}/${item.id}`)}
              >
                {item.title_tc}
              </span>
              /
              <span
                className="y-2 bg-white text-dark text-decoration-none px-2"
                onClick={() => goLink("sc", `/sc/${item.kind}/${item.id}`)}
              >
                {item.title_sc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchResult;
