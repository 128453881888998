import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import searchPublisher from "../publishers/searchPublisher";

function SearchPlatform() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const inputRef = React.useRef();

  React.useEffect(() => {
    searchPublisher.subscribe("searchPlanform", (state) => {
      setOpen(state);
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    });
  }, []);

  const submitHandle = (e) => {
    e.preventDefault();
    setOpen(false);
    setValue("");
    i18n.changeLanguage(language);
    window.location.href = `/${language}/search/${value}`;
    //history.push(`/${language}/search?q=${value}`);
  };

  return (
    <div className={"search-platform" + (open ? " active" : "")}>
      <div
        className="search-platform-close"
        onClick={() => setOpen(false)}
      ></div>
      <form className="search-platform-form" onSubmit={(e) => submitHandle(e)}>
        <input
          ref={inputRef}
          type="text"
          name="search-platform_text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={t("search_placeholder")}
        />
      </form>
    </div>
  );
}

export default SearchPlatform;
