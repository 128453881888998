import React from "react";
import Banner from "../components/Banner";
import Breadcrumb from "../partials/Breadcrumb";
import LeftImageRightContent from "../components/LeftImageRightContent";
import PrevNext from "../partials/PrevNext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

function ProductDetail({ server }) {
  const { t, i18n } = useTranslation();
  const { language } = useParams();
  const serverData = server.read();
  // console.log(serverData);
  const pageTitle = serverData.product["title_" + language];

  const bannerDataItem = {
    src: `${config.baseURL}/storage/${serverData.page.banner}`,
  };

  const breadcrumbData = [
    { title: "Home", link: "/" },
    { title: t("wine_packaging"), link: `/${language}/wine-packaging` },
    { title: pageTitle },
  ];

  window.scrollTo(0, 0);

  return (
    <>
      <div className="product-detail">
        <Banner data={bannerDataItem} />
        <Breadcrumb data={breadcrumbData} />
        <LeftImageRightContent serverData={serverData} />
        <PrevNext serverData={serverData} folder="wine-packaging" />
      </div>
    </>
  );
}

export default ProductDetail;
