import React from "react";
import Banner from "../components/Banner";
import Breadcrumb from "../partials/Breadcrumb";
import config from "../config";
import Gallery4column from "../components/Gallery4Column";
import { useTranslation } from "react-i18next";
import { resource } from "../fetchDataApp";

function Home() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const serverData = resource.homePage.read();

  const bannerDataItem = {
    src: `${config.baseURL}/storage/${serverData.banner}`,
  };

  const breadcrumbData = [{ title: t("home") }];
  const gallery4columnData = [
    {
      id: serverData.a_product_id,
      title: serverData["a_title_" + language],
      src: `${config.baseURL}/storage/${serverData.a_source}`,
      type: serverData.a_source.split(".").pop() == "mp4" ? "video" : "image",
      itemType: serverData.a_type,
      link: serverData.a_product_id,
    },
    {
      id: serverData.b_product_id,
      title: serverData["b_title_" + language],
      src: `${config.baseURL}/storage/${serverData.b_source}`,
      type: serverData.b_source.split(".").pop() == "mp4" ? "video" : "image",
      itemType: serverData.b_type,
      link: serverData.b_product_id,
    },
    {
      id: serverData.c_product_id,
      title: serverData["c_title_" + language],
      src: `${config.baseURL}/storage/${serverData.c_source}`,
      type: serverData.c_source.split(".").pop() == "mp4" ? "video" : "image",
      itemType: serverData.c_type,
      link: serverData.c_product_id,
    },
    {
      id: serverData.d_product_id,
      title: serverData["d_title_" + language],
      src: `${config.baseURL}/storage/${serverData.d_source}`,
      type: serverData.d_source.split(".").pop() == "mp4" ? "video" : "image",
      itemType: serverData.d_type,
      link: serverData.d_product_id,
    },
  ];

  window.scroll(0, 0);

  return (
    <div className="home">
      <Banner data={bannerDataItem} />
      <Breadcrumb data={breadcrumbData} />
      <Gallery4column data={gallery4columnData} />
    </div>
  );
}

export default Home;
