import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { resource } from "../fetchDataApp";

function Footer() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const serverData = resource.socialMedia.read();

  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="footer">
      <hr className="mb-5" style={{ borderTop: "1px solid #f5f5f5" }} />
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 col-md-4 mb-5 mb-lg-0">
            <Link to={`/${language}`} className="text-decoration-none">
              <img src="/images/logo.jpg" />
            </Link>
          </div>
          <div className="col-12 col-md-4 mb-5 mb-lg-0">
            <h2>{t("sitemap")}</h2>
            <ul>
              <li>
                <span className="arrow-right"></span>
                <Link to={`/${language}`} className="text-uppercase">
                  {t("home")}
                </Link>
              </li>
              <li>
                <span className="arrow-right"></span>
                <Link to={`/${language}/about-us`} className="text-uppercase">
                  {t("about_us")}
                </Link>
              </li>
              <li>
                <span className="arrow-right"></span>
                <Link to={`/${language}/products`} className="text-uppercase">
                  {t("product_gallery")}
                </Link>
              </li>
              <li>
                <span className="arrow-right"></span>
                <Link
                  to={`/${language}/wine-packaging`}
                  className="text-uppercase"
                >
                  {t("wine_packaging")}
                </Link>
              </li>
              <li>
                <span className="arrow-right"></span>
                <Link to={`/${language}/contact-us`} className="text-uppercase">
                  {t("contact_us")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 mb-5 mb-lg-0 d-flex">
            <div className="align-self-end">
              <p>
                T: + {serverData.tel}
                <br />
                E: <a mailto={serverData.email}>{serverData.email}</a>
              </p>
              <div className="align-self-end">
                {serverData.facebook && (
                  <a
                    href={serverData.facebook}
                    target="_blank"
                    className="mr-3"
                  >
                    <i
                      className="fab fa-facebook-f"
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                )}
                {serverData.instagram && (
                  <a
                    href={serverData.instagram}
                    target="_blank"
                    className="mr-3"
                  >
                    <i
                      className="fab fa-instagram"
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                )}

                {serverData.linkedin && (
                  <a
                    href={serverData.linkedin}
                    target="_blank"
                    className="mr-3"
                  >
                    <i
                      className="fab fa-linkedin-in"
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                )}

                {serverData.twitter && (
                  <a href={serverData.twitter} target="_blank" className="mr-3">
                    <i className="fab fa-twitter" style={{ fontSize: 20 }}></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col">
            <small>Copyright {year} Nyonya.</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
