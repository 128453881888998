import React from "react";

function Loading() {
  console.log("loading....");
  return (
    <div className="custom-loading">
        <div className="indictor"></div>
    </div>
  );
}

export default Loading;